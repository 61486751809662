import styled from "styled-components";

const Arrow = styled.div`
  width: 42px;
  height: 42px;
  background: url(${({icon}) => icon}) center center no-repeat;
  z-index: 1000;
  cursor: pointer;
  margin: 20px auto 0;
`;

export default Arrow