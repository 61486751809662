import React, { useState } from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import { colors } from "../../style/style";

const AccordionBody = styled.div`
  max-width: 388px;
  position: relative;
  @media(max-width: 560px) {
    right: -40px;
  }
`
const AccordionTitle = styled.h3`
  margin: 0;
  position: relative;
  padding-right: 40px;
  font-size: 75px;
  font-weight: 700;
  line-height: 64px;
  color: ${ colors.dark };
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif;
  @media (max-width: 660px) {
    font-size: 50px;
    line-height: 64px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 20px;
    height: 20px;
    background: url("data:image/svg+xml,%3Csvg width='10' height='20' viewBox='0 0 10 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 19L8 10L1 1' stroke='%232A2034' stroke-width='2'/%3E%3C/svg%3E%0A") no-repeat;
    transform: rotate(${ props => props.collapsed ? 0 : 90 }deg) translateY(-50%);
    transition: transform .3s ease;
    transform-origin: left top;
  }
`
const AccordionInner = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${ props => props.collapsed ? 0 : "660px" };
  padding-left: 28px;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  overflow: hidden;
  transition: max-height .3s ease;
`
const AccordionLink = styled(Link)`
  font-weight: 500;
  width: 100%;
  color: ${colors.dark};
  padding: 7px 0;
  margin: 0 0 20px;
  position: relative;
  text-align: left;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  &:first-child {
    margin-top: 20px;
  }
  &:after {
    content: '';
    display: block;
    background: ${colors.orange};
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: -2px;
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transition: clip-path 0.2s ease;
  }

  &:hover {
    color: ${colors.dark};
    text-decoration: none;

    &:after {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
`
const linksQuery = graphql`
    {
        allDatoCmsProduct(sort: {fields: position, order: ASC}) {
            nodes {
                id
                slug
                title
            }
        }
    }`
const ProductsAccordion = ({toggleOpen}) => {
    const [ collapsed, setCollapsed ] = useState(true);
    const {allDatoCmsProduct: {nodes: links}} = useStaticQuery(linksQuery)

    return (
        <AccordionBody>
            <AccordionTitle collapsed={ collapsed } onClick={ () => setCollapsed(!collapsed) }>Products</AccordionTitle>
            <AccordionInner collapsed={ collapsed }>
                {
                    links.map(l=> (
                        <AccordionLink key={l.id} to={`/product-details#${l.slug}`} onClick={toggleOpen}>
                          {l.title}
                        </AccordionLink>
                    ))
                }
            </AccordionInner>
        </AccordionBody>
    )
}

export default ProductsAccordion;
