import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Input as Selector, Underline } from "./Form/Form";
import { DropDown, Search } from "./SearchBar";
import { colors } from "../style/style";

import departmentIcon from "../static/images/icons/icon_department.svg"
import selectIcon from "../static/images/icons/select_icon.svg"


const DropDownItem = styled.div`
  padding: 5px 0;
  margin: 10px 0;
  cursor: pointer;

  span {
    pointer-events: none;
  }
  &:hover {
      color: ${colors.orange};
  }
`


const DropDownIcon = styled.img`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) ${props => props.isRotate ? "rotate(180deg)" : ""};
    cursor: pointer;
`

const Select = ({ placeholder, icon, isRequired, options, initialValue, maxHeight, className }) => {

    const [ selected, setSelected ] = useState(initialValue || "");
    const [ display, setDisplay ] = useState(false);

    const dropdownRef = useRef(null);


    useEffect(() => {
        const onClickHandler = (e) => {
            const { current: wrap } = dropdownRef;
            if (wrap && !wrap.contains(e.target)) {
                setDisplay(false)
            }
        }
        document.addEventListener('click', onClickHandler)
        return () => {
            document.removeEventListener('click', onClickHandler)
        }
    })

    const onSelectItemClick = (e) => {
        if (!e.target.dataset.type) return false
        setSelected(e.target.textContent)
        setDisplay(false)
    }

    const onSelectClick = () => {
        if (display) return setDisplay(false)
        setDisplay(true)
    }


    return (
        <Search ref={ dropdownRef } className={className}>
            <Selector type="text"
                  id="form_type"
                  name="form_type"
                  onClick={onSelectClick}
                  autoComplete="off"
                  value={selected}
                  onChange={() => console.log()}
                  color={ colors.dark }
                  required={ isRequired }
                  icon={ icon ? icon : departmentIcon }
                  placeholder={ placeholder ? placeholder : "Choose department*" }
                  borderColor={ 'rgba(42, 42, 42, 0.4)' }
                  style={{caretColor: "transparent", cursor: "pointer"}}
            />
            <Underline/>
            <DropDownIcon src={selectIcon} isRotate={display} onClick={() => setDisplay(!display)} />
            
             {
                display &&
                <DropDown maxHeight={ maxHeight }>
                    {options.map(option => (
                        <DropDownItem 
                            key={option.type} 
                            onClick={ onSelectItemClick } 
                            data-type={option.type}
                            color={colors.orange}
                        >
                            {option.title}
                        </DropDownItem>
                    ))}
                </DropDown>
            } 
        </Search>
    )
}

export default Select;