import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../style/style";
import nameIcon from "../../static/images/icons/icon_name.svg"
import phoneIcon from "../../static/images/icons/icon_phone.svg"
import iconDepartment from "../../static/images/icons/icon_department.svg"
import emailIcon from "../../static/images/icons/icon_email.svg"
import agencyIcon from "../../static/images/icons/icon_agency.svg"
import locationIcon from "../../static/images/icons/icon_location.svg"
import closeIcon from "../../static/images/icons/icon_close.svg"
import SearchBar from "../SearchBar";
import Select from '../Select'
import SelectedPrograms from "../SelectedPrograms";
import { graphql, useStaticQuery } from "gatsby";

const statesName = graphql`
    {
        allDatoCmsState {
            nodes {
                id
                name
                state
            }
        }
    }
`

const StyledForm = styled.form`
  position: relative;
  text-align: center;
`
export const Input = styled.input`
  position: relative;
  display: block;
  width: 100%;
  min-width: 350px;
  max-height: 42px;
  padding: ${ props => props.padding || "11px 12px 11px 42px" };
  border-style: none none solid;
  border-bottom: 2px solid rgba(43, 33, 53, .4);
  background: url("${ props => props.icon || "" }") 5px 50% no-repeat transparent;
  color: ${ props => props.color || colors.dark };
  font-family: "SF Pro Display";
  font-weight: 500;
  font-size: 14px;
  outline: none;
  cursor: text;
  margin: 0;
  border-radius: 0;
  -webkit-appearance: none;  
  &:hover + div,
  &:focus + div {
    pointer-events: none;
    width: 100%;
  }

  @media (max-width: 580px) {
    min-width: 295px;
  }
`
const TextArea = styled(Input).attrs({ as: "textarea" })`
  max-height: 40px;
  resize: none;
  transition: max-height .3s ease;
`;
export const Underline = styled.div`
  position: absolute;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: ${ colors.orange };
  will-change: width;
  transition: width .3s ease;
`
export const InputWrapper = styled.div`
  position: relative;
`
const FormTitle = styled.h2`
  margin-bottom: 5px;
  font-family: SF Pro Display;
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  @media (max-width: 500px) {
    max-width: 230px;
    font-size: 24px;
    line-height: 29px;
  }
`
export const FormSubTitle = styled.h3`
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 145%;
  opacity: .5;
  margin-bottom: 24px;
`
const FormBtn = styled.button`
  display: inline-block;
  min-height: 45px;
  min-width: 168px;
  padding: 11px 33px !important;
  border: 2px solid ${ props => props.color };
  border-radius: 50px;
  color: ${ props => props.color || colors.dark };
  font-family: "SF Pro Display";
  font-size: 14px;
  font-weight: 700;
  background: transparent;
  transition: border .3s ease;
  text-decoration: none;
  text-align: center;

  &:hover {
    border: 2px solid ${ props => props.hovColor || props.color };
    text-decoration: none;
    color: ${ props => props.color || colors.dark }
  }

  &:active, &:focus {
    outline: none;
    text-decoration: none;
  }

  &:disabled {
    pointer-events: none;
    opacity: .4;
  }

`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 40px);
  grid-column-gap: 70px;
  grid-row-gap: 40px;
  margin-bottom: 50px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 40px);
  }
  @media (max-width: 500px) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(5, 40px);
  }
`
const CloseBtn = styled.button`
  position: absolute;
  top: -20px;
  right: -50px;
  width: 22px;
  height: 22px;
  padding: 20px;
  border: none;
  background: url(${ closeIcon }) center center no-repeat;
  cursor: pointer;
  transform: translate(10px, -20px);

  &:active {
    outline: none;
  }

  @media (max-width: 767px) {
    top: 0;
    right: 0;
  }
  @media (max-width: 500px) {
    transform: translate(10px, -10px);
  }
`

const InputPhoneNumber = () => {

  const [number, setNumber] = useState("")

  const handleInputChange = (value) => {
    const num = value.target.value
    const reg = /^(?=.*[+0-9])[- +()0-9]+$/  
    if (reg.test(num) || num === "") setNumber(num)
  }

  return <Input value={number} 
                onChange={handleInputChange} 
                icon={ phoneIcon } 
                id="phone" 
                maxlength="40" 
                name="phone" 
                type="tel"
                placeholder={ "Phone" }
        />
}

const TextBox = () => {
    const textArea = useRef(null);
    useEffect(()=> {
        const element = textArea.current
        if(!element) return null;
        let offset = element.offsetHeight - element.clientHeight;
        const resize = (event) => {
            const height = event.target.scrollHeight < 40 ? 40 : event.target.scrollHeight;
            if(event.target.value.length < 1) {
                event.target.style.maxHeight = "40px"
                return null
            }
            if(height >= 68) event.target.style.maxHeight = "68px"
            else event.target.style.maxHeight = height + offset + 'px'
        }
        element.addEventListener('input', resize);
        return () => element.removeEventListener('input', resize);
    },[])
    return (
        <TextArea id="comments" name="comments" ref={textArea} rows={ 3 } required placeholder={ "How we can help?*" } padding={ "11px 12px 11px 5px" } />
    )
}

export const Form = ({ cards, products, setList, action, title, type, onClose, isDepartmentForm }) => {
    const currentAction = action ? action : 'https://marketing.amsuisse.com/l/868671/2021-05-28/ld18rq';

    const [stateCode, setStateCode] = useState("")

    const departmentsTypes = [
      {type: "General Information", title: "Marketing & General Information"},
      {type: "Admitted Markets", title: "Admitted Markets"},
      {type: "E&S", title: "E&S and Specialty Markets"}
    ]

    const { allDatoCmsState: { nodes: states } } = useStaticQuery(statesName);
  
    const onSubmit = (e) => {
        e.preventDefault()
        const value = new FormData(document.querySelector("form"));

        const state = value.get("state_code");
        const stateCode = states.find((s) => (s.name === state));
        value.set("state_code", stateCode.state);

        
        if (isDepartmentForm) {
          const typeFromInput = value.get("form_type");
          const formType = departmentsTypes.find(t => t.title === typeFromInput)
          value.delete("form_type") // remove unnecessary value 
          value.set("type", formType.type);
        }
        

        fetch(currentAction, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            },
            body: new URLSearchParams(value),
        })
        .then(res => {
          console.log('response redirect', res)
          if (res.redirected) {
            window.location.href = res.url
          }
        })
        .catch(_ => console.log('Error while submitting the form'))
    };

    return (
        <StyledForm id="form" action={currentAction} method="POST" target="_self">
            <CloseBtn onClick={ onClose }/>
            <div style={ { marginBottom: "50px", textAlign: "left" } }>
                <FormTitle>{isDepartmentForm ? "Let us know how we can help" : title} </FormTitle>
                {!isDepartmentForm && <FormSubTitle>Let us know how we can help</FormSubTitle> }
            </div>
            <div style={{ marginBottom: "65px" }}>
                <Grid>
                    <input type="hidden" name='captcha_settings' value='{"keyname":"New_API_Key","fallback":"true","orgId":"00D3j0000002S7m","ts":""}' />
                    <input type="hidden" name="oid" value="00D3j0000002S7m" />
                    <input type="hidden" id="country_code" name="country_code" value="US" />
                    <input type="hidden" id="state_code" name="state_code" value={stateCode} />

                    <input type="hidden" id="lead_type" name="lead_type" value="Broker" />
                    {isDepartmentForm && 
                      <InputWrapper>
                        <Select placeholder={ "Choose department*" }
                                icon={ iconDepartment }
                                initialValue={title}
                                options={departmentsTypes}
                                isRequired
                                className={ "form" }
                                maxHeight={ "150px" }
                        />
                      </InputWrapper>}
                    { !isDepartmentForm && type && <input type="hidden" id="type" name="type" value={type} />} 
                    
                    <InputWrapper>
                        <Input icon={ nameIcon } id="first_name" maxlength="40" name="first_name" required placeholder={ "First Name*" }/>
                        <Underline/>
                    </InputWrapper>
                    <InputWrapper>
                        <Input icon={ nameIcon } id="last_name" maxlength="40" name="last_name" required placeholder={ "Last Name*" }/>
                        <Underline/>
                    </InputWrapper>
                    <InputWrapper>
                        <Input icon={ agencyIcon } id="company" maxlength="40" name="company" type="text" required placeholder={ "Agency name*" }/>
                        <Underline/>
                    </InputWrapper>
                    <InputWrapper>
                        <Input icon={ emailIcon } id="email" maxlength="80" type="email" name="email" required placeholder={ "Email*" }/>
                        <Underline/>
                    </InputWrapper>
                    <InputWrapper>
                        <SearchBar placeholder={ "State" }
                                  icon={ locationIcon }
                                  setState={setStateCode}
                                  isRequired
                                  states={states}
                                  className={ "form" }
                                  maxHeight={ "150px" }
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <InputPhoneNumber icon={ phoneIcon } id="phone" maxlength="40" name="phone" type="tel" placeholder={ "Phone" }/>
                        <Underline/>
                    </InputWrapper>
                </Grid>
                <InputWrapper>
                    <TextBox />
                    <Underline/>
                </InputWrapper>

                {
                    products && 
                        <InputWrapper>
                            <SelectedPrograms cards={cards} products={products} setList={setList} />
                        </InputWrapper>
                }

                { products && <input type="hidden" id="insurtech_programs" name="insurtech_programs" value={products.join()} />}
            </div>
            
            <div className="g-recaptcha" data-sitekey="6LdzkdEaAAAAAFh2mgDpjOdxvxmlRQSR0XwSCoQC" />
            <FormBtn type="submit" disabled={false} color={colors.dark} hovColor={colors.orange}>Submit</FormBtn>
        </StyledForm>
    )
}
