import React, { useRef } from "react";
import Portal from "./Portal";
import styled from "styled-components";
import {colors} from "../../style/style";
import {Form} from "./Form";
import {motion} from "framer-motion";
import {slideInUp} from "../../style/motion-variants";
import { useOnClickOutside } from "../../utils/hooks/useOnClickOutside";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(43, 33, 53, .8);
  z-index: 999;
  outline: none;
`
const FormWrapper = styled(motion.div)`
  padding: 70px 100px;
  width: 970px;
  background-color: ${colors.light};
  
  @media (max-width: 1024px) {
    width: 670px;
    height: 80%;
    overflow: scroll;
  }
  @media (max-width: 767px) {
    padding: 50px;
  }
  @media (max-width: 700px) {
    width: 80%;
    padding: 50px;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
    padding: 20px;
  }
`

const FormPopup = ({ cards, products, setList, onClose, isOpen, action, title, type, name, isDepartmentForm }) => {
    const form = useRef(null);
    useOnClickOutside(form, onClose);
    if (!isOpen) return null;
    return (
        <Portal>
            <Overlay
                role="button"
                tabIndex={0}

            >
                <FormWrapper exit="exit"
                             variants={ slideInUp }
                             initial="initial"
                             animate={isOpen ? "open" : "closed"}
                             ref={form}>
                    <Form action={action} 
                          title={title} 
                          type={type} 
                          name={name} 
                          onClose={onClose} 
                          cards={cards} 
                          products={products} 
                          setList={setList}
                          isDepartmentForm={isDepartmentForm}
                          />
                </FormWrapper>
            </Overlay>
        </Portal>
    )
}

export default FormPopup;