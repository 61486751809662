import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import { graphql, useStaticQuery } from 'gatsby';
import {colors} from "../../style/style";
import {motion} from "framer-motion";

import * as css from './product-list.module.css'

const productsQuery = graphql`{
     allDatoCmsProduct(sort: {fields: position, order: ASC}) {
        nodes {
            id
            slug
            title
            shortDescription
            cover {
                url(imgixParams: {w: "1440"})
            }
        }
    } 
    allDatoCmsProductList {
    nodes {
      products {
        id
        shortDescription
        slug
        title
        cover {
          url(imgixParams: {w: "1440"})
        }
      }
    }
  }
}`;

const productAnimation = {
    open: i => ({
        opacity: 1,
        clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 100%)`,
        transition: {
            delay: 0.3,
            duration: .5
        },
    }),
    closed: {
        opacity: 0,
        clipPath: `polygon(0 0, 0 0, 0 100%, 0 100%)`
    },
};


const Product = ({ wasMoved, slug, title, shortDescription, cover  }) => {
    function getStyle(element) {
        const root  = document.documentElement;
        const body  = document.body;
        const rect = element.getBoundingClientRect();
        const scrollTop  = window.pageYOffset || root.scrollTop  || body.scrollTop  || 0;
        const scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;
        const clientTop  = root.clientTop  || body.clientTop  || 0;
        const clientLeft = root.clientLeft || body.clientLeft || 0;
        const top = Math.round(rect.top + scrollTop - clientTop);
        const left = Math.round(rect.left + scrollLeft - clientLeft);
        const screenWidth = document.documentElement.clientWidth;
        const screenHeight = document.documentElement.clientHeight;
        const width = rect.width / screenWidth;
        const height = rect.height / screenHeight;

        return {
            position: 'absolute',
            zIndex: '100',
            background: `linear-gradient(90deg,
                rgba(42, 32, 52, 0.8) 13.24%, rgba(42, 32, 52, 0) 100%),
                rgba(35, 5, 85, 0.3) ${element.style.backgroundImage} no-repeat center`,
            backgroundSize: 'cover',
            top: `0px`,
            left: `0px`,
            transform: `translate(${left}px, ${top}px) scale(${width.toFixed(3)}, ${height.toFixed(3)})`,
            transformOrigin: 'top left',
            height: '100%',
            width: '100%',
            animation: 'out .3s .01s ease-out forwards',
            willChange: 'transform',
        };
    }

    const exitTransition = {
        length: 0.4,
        zIndex: 2,
        state: { color: colors.light },
        trigger: ({ exit, node, e }) => {
            const target = e.target.closest('a');
            const style = getStyle(target)
            const image = document.createElement('div');
            for(const property in style) {
                image.style[property] = style[property]
            }
            node.querySelector('header').style.color = "#FFFFFF";
            node.appendChild(image);
            node.style.position = 'relative';
            node.style.pointerEvents = 'none';
        },
    };
    
    const entryTransition = {
        zIndex: 1,
        delay: 0.4,
        state: { hash: slug },
        trigger: ({ exit }) => exit.state.color = null
    };

    return (
            <TransitionLink
                to={`/product-details#${slug}`}
                className={css.inner}
                role="link"
                exit={exitTransition}
                entry={entryTransition}
                style={{
                    pointerEvents: wasMoved ? 'none' : '',
                    backgroundImage: `url(${cover.url})`
                }}
                onDragStart={e => {
                    e.preventDefault()
                }}
            >
                <h2>{title}</h2>
                <p className={css.description} >{shortDescription}</p>
            </TransitionLink>
    )
    
}

const ProductsList = () => {
    const {allDatoCmsProductList} = useStaticQuery(productsQuery);
    const {products} = allDatoCmsProductList.nodes[0];
    const productsRef = React.useRef();
    const [touch, setTouch] = React.useState(false);
    const [wasMoved, setWasMoved] = React.useState(false)
    const [hover, setHover] = React.useState(0)

    const raf = React.useRef();

    function handleMouseMove(e) {
        if(touch) {
            if(e.movementX !== 0) {
                setWasMoved(true);
            }

            if(productsRef.current) {
                productsRef.current.scrollBy({
                    top: 0,
                    left: -e.movementX
                })
            }
        } else {
            let clientX = e.clientX;
            let edge = Math.floor(window.innerWidth / 6);
            /**
             * @name l
             * @property {number} scrollLeft
             * @property {number} scrollWidth
             * @property {number} offsetWidth
             */
            let l = productsRef.current;

            if (clientX < edge && hover !== 1 && l.scrollLeft !== 0) {
                return setHover(1)
            }
            if (clientX > window.innerWidth - edge && hover !== 2 && l.scrollWidth !== l.offsetWidth + l.scrollLeft) {
                return setHover(2)
            }

            if (clientX >= edge && clientX <= window.innerWidth - edge && hover !== 0) {
                setHover(0)
            }
        }
    }

    function scroll() {
        let x = 30
        /**
         * @name l
         * @property {number} scrollLeft
         * @property {number} scrollWidth
         * @property {number} offsetWidth
         */
        let l = productsRef.current;
        if ((hover === 1 && l.scrollLeft === 0) || (hover === 2 && l.scrollWidth === l.offsetWidth + l.scrollLeft)) {
            cancelAnimationFrame(raf.current)
            return setHover(0)
        }

        if(hover === 0) {
            return
        }

        raf.current = requestAnimationFrame(() => {
            if(productsRef.current) {
                productsRef.current.scrollBy({
                    top: 0,
                    left: hover === 1 ? -x : x
                })

                scroll()
            }
        })
    }

    React.useEffect(() => {
        if(hover !== 0) {
            scroll()
        } else {
            cancelAnimationFrame(raf.current)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hover])

    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    return <div
        style={wasMoved ? {cursor: 'grab'} : {}}
        className={`${css.products} ${hover > 0 ? css.scrolling : ''} swiper-no-swiping`}
        ref={productsRef}
        onMouseDown={() => {
            setTouch(true)}
        }
        onMouseLeave={() => {
            setHover(0)
            cancelAnimationFrame(raf.current)
            setTouch(false)
        }}
        onMouseUp={() => {
            setTouch(false);
            setWasMoved(false)
        }}
        onMouseMove={handleMouseMove}
    >
        {
            products.map((product, i) => 
                <motion.div className={css.item} key={product.id} initial="closed" animate={"open"} custom={i} variants={productAnimation} >
                    <Product  wasMoved={wasMoved} {...product} /> 
                </motion.div>
            )
        }

        <div className={css.lastNode}/>
    </div>
}

export default ProductsList

