import React from "react";
import {motion} from "framer-motion";
import styled from "styled-components";
import {Link} from "gatsby";
import {colors} from "../../../style/style";
import {listAnimationVariant, listItemAnimationVariant, sidebar} from "../../../style/motion-variants";
import {StyledButton} from "../PageHeader";
import ProductsAccordion from "../../Accordion/ProductsAccordion";

const NavigationBody = styled.div`
  position: absolute;
  top: 169px;
  right: 92px;
  max-height: 1025px;
  margin: 0;
  padding: 0;
  z-index: 100;
  text-align: right;

  & > div:not(:last-child) {
    margin-bottom: 50px;
    @media (max-width: 660px) {
      margin-bottom: 10px;
    }
  }
`
const NavLink = styled(Link)`
  font-size: 75px;
  font-weight: 700;
  line-height: 64px;
  color: ${colors.dark};
  @media (max-width: 660px) {
    font-size: 50px;
    line-height: 64px;
  }

  &:active,
  &:hover {
    text-decoration: none;
    color: ${colors.dark};
  }
`
const MotionDiv = styled(motion.div)`
  position: absolute;
  top: 174px;
  left: 50px;
  z-index: 900;
  @media (max-width: 560px) {
    top: 480px;
    left: unset;
    right: 92px;
  }
`
const Bg = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  background: ${colors.light};
  z-index: 100;
`
const Navigation = ({links, isOpen, toggleOpen}) => {
    const pointerEvents = isOpen ? "all" : "none";

    return <motion.nav initial={false} animate={isOpen ? "open" : "closed"} style={{zIndex: 2}}>
        <Bg variants={sidebar}/>
        <motion.div variants={listAnimationVariant} style={{pointerEvents}}>
            <NavigationBody>
                <motion.div variants={listItemAnimationVariant}>
                    <ProductsAccordion toggleOpen={toggleOpen}/>
                </motion.div>
                {links.map((item, index) => (
                    <motion.div key={index} variants={listItemAnimationVariant}>
                        <NavLink to={item.href}>{item.title}</NavLink>
                    </motion.div>
                ))}
                <motion.div variants={listItemAnimationVariant}>
                    <StyledButton data-screen="mobile" to={'/online-quoting/'} color={colors.dark}>Online quoting</StyledButton>
                </motion.div>
            </NavigationBody>
            <MotionDiv variants={listItemAnimationVariant}>
                <StyledButton data-screen="desktop" to={'/online-quoting/'} color={colors.dark}>Online quoting</StyledButton>
            </MotionDiv>
        </motion.div>
    </motion.nav>
}

export default Navigation
