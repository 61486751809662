import React from "react";
import styled from "styled-components";
import menuIcon from "../static/images/icons/selected_programs_icon.svg";
import { colors } from "../style/style";
import { useOnClickOutside } from "../utils/hooks/useOnClickOutside";

const Container = styled.div`
    position: relative;
    max-width: 350px;
    margin: 42px 0 20px;
    cursor: pointer;

    &:hover,
    &:focus {
        &:before {
            width: 100%;
        }
    }

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        opacity: 0.4;
        background-color: #2A2034;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 0;
        height: 2px;
        background-color: ${colors.orange};
        will-change: width;
        transition: width .3s ease;
        z-index: 10;
    }
    @media all and (max-width: 767.98px) {
        max-width: 100%;
    }
`

const Button = styled.div`
    width: 100%;

    div {
        display: flex;
        align-items: center;
        padding: 11px 0;
    }

    img {
        margin-right: 18px;
    }

    span {
        font-weight: 500;
        font-size: 14px;
    }
`

const Items = styled.div`
    display: ${props => props?.visible ? "block" : "none"};
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 150px;
    overflow-y: auto;
    padding: 0 15px 7px;
    background: #FFFFFF;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 
                0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481), 
                0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185), 
                0px 20px 13px rgba(0, 0, 0, 0.035), 
                0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 
                0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
`

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    cursor: pointer;
`

const Box = styled.div`
    width: 18px;
    height: 18px;
    border: 1px solid #2A2034;
    position: relative;

    &:after {
        display: ${props => props?.checked ? "block" : "none"};
        content: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 6.5L6 11L15.5 1.5' stroke='%23FF5C00' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
        position: absolute;
        top: -3px;
        left: 2px;
    }
`

const SelectedPrograms = ({ cards, products, setList }) => {
    const [visible, setVisible] = React.useState(0);
    const container = React.useRef(null);
    const setHidden = () => setVisible(false)
    useOnClickOutside(container, setHidden);
    
    return (
        <Container ref={container}>
            <Button onClick={() => setVisible(!visible)}>
                <div>
                    <img src={menuIcon} alt="menu icon" />
                    <span>Selected Programs</span>
                </div>
            </Button>

            <Items visible={visible}>
                {
                    cards.map((card) => 
                        <Item key={card} onClick={() => setList(card)}>
                            <span>{card}</span>
                            <Box checked={products.indexOf(card) !== -1}/>
                        </Item>
                    )
                }
            </Items>             
        </Container>
    )
}

export default SelectedPrograms
