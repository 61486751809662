import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import lottie from 'lottie-web';

import { colors } from '../style/style';
import { fadeInLeft, fadeInUp } from '../style/motion-variants';
import animation from '../animation/main-animation.json';

import useWindowDimensions from '../utils/hooks/useWindowWidth';
import Layout from '../components/layout';
import PageHeader from '../components/PageHeader/PageHeader';
import PageSection from '../components/PageSection';
import SlideInElement from '../components/SlideInElement/SlideInElement';
import FormContainer from '../components/Form/FormContainer';
import SlideInMinutes from '../components/Slides/SlideInMinutes';
import SlideInsurance from '../components/Slides/SlideInsurance';
import SlideProducts from '../components/Slides/SlideProducts';
import SlideFooter from '../components/SlideFooter';
import { tagLineText1, tagLineText2 } from '../components/Slides/Texts';
import { SubTitle, TagLine } from '../components/PageParts';

import Slider from '../components/Slider/Slider';
import Scroll from '../components/Slider/Scroll';

import bgLightElement from '../static/images/bg-element-light.svg';

import Arrow from '../components/Arrow'
import arrowUp from "../static/images/icons/arrow_up.svg";

import { handleLottieStop, handleLottieTouch, handleTransformLottie } from "../utils/helpers/lottieHandler";

export const LottieOuter = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    max-width: 1260px;
    max-height: 400px;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 90;
`;
export const LottieInner = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    width: 600px;
    height: 600px;
    z-index: 10;
    pointer-events: none;
    transform: translateY(-50%);
    @media (max-width: 1200px) {
        width: 560px;
        height: 560px;
        transform: translateY(-60%);
    }
    @media (max-width: 560px) {
        top: 163px;
        width: 315px;
        height: 315px;
    }
`;
export const Mock = styled(LottieInner)`
    opacity: 0;
    background: url('${(props) => props?.mockImg}') center center / cover no-repeat;
    @media (max-width: 560px) {
        width: 315px;
        height: 315px;
        top: 163px;
        transform: translate(0);
    }
`;
export const swiperScrollOptions = {
    sensitive: 0.5,
    forceToAxis: true,
    thresholdDelta: 20,
    thresholdTime: 500,
};

export const FormBtn = styled.button`
    max-width: 250px;
    padding: 0;
    color: ${colors.light};
    border: none;
    background: transparent;
    font-family: 'SF Pro Display', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    transition: color 0.3s ease;
    cursor: pointer;

    &:active,
    &:focus {
        outline: none;
    }

    &:hover {
        color: ${colors.orange};
        text-decoration: underline;
    }
`;

const IndexPage = ({ exit }) => {
    const headerRef = useRef();
    const lottieContainer = useRef();
    const [visible, setVisible] = React.useState(0);
    const [activeElement, setActiveElement] = useState(0);
    const [lastElement, setLastElement] = useState(0);
    const [lottieNum, setLottieNum] = useState(0);
    const { width } = useWindowDimensions();
    const stateColor = exit?.state?.color;

    useEffect(() => {
        const heroAnimation = document.querySelector('#hero-anim');
        if (lottieContainer && !heroAnimation) {
            let anim = lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'canvas',
                name: 'main',
                loop: false,
                autoplay: true,
                animationData: animation,
                rendererSettings: {
                    id: 'hero-anim',
                },
            });
            anim.addEventListener('enterFrame', () => {
                if (anim.currentFrame >= 119) {
                    anim.removeEventListener('enterFrame');
                    anim.goToAndStop(119, true);
                }
            });
        }
        if (lottieContainer?.current?.style && (activeElement === 2 || lastElement === 1)) {
            lottieContainer.current.style.opacity = 0;
        }
    }, [activeElement, lastElement, visible]);

    useEffect(() => {
        if (lottieNum === 1) {
            lottie.goToAndStop(119, true);
            lottie.play('main');
        }
    }, [lottieNum]);

    const onArrowClick = (slide, last) => {
        if (!last) last = slide - 1;
        setActiveElement(slide);
        setLottieNum(1);
        setLastElement(last);
    };

    const lottieTouch = useCallback(() => {
        handleLottieTouch(lottieContainer)
    }, [lottieContainer])

    const lottieTransform = useCallback((e) => {
        handleTransformLottie(lottieContainer, e)
    }, [lottieContainer])

    const lottieStop = useCallback(() => {
        handleLottieStop(lottieContainer)
    }, [lottieContainer])


    const handleChangeSlide = (active, last) => {
        setActiveElement(active);
        setLastElement(last);
        if (activeElement < 1) {
            setLottieNum(1);
        }
        if (last === 1 && visible !== 1) {
            setVisible(1);
        }
    };

    return (
        <Layout>
            <PageHeader
                ref={headerRef}
                color={
                    stateColor ? stateColor : activeElement === 0 || activeElement === 3 ? colors.dark : colors.light
                }
            />
            <Slider
                autoSlidePercent={35}
                msAnimationTime={0.5}
                swipeDelayPercent={7}
                activeElement={activeElement}
                lastElement={lastElement}
                handleChangeSlide={handleChangeSlide}
                lottieTransform={lottieTransform}
                lottieTouch={lottieTouch}
                lottieStop={lottieStop}
                headerRef={headerRef}
            >
                <SlideInMinutes width={width} opacity={1} vis={visible} handleClick={onArrowClick} />
                <SlideInsurance isActive={activeElement === 1} vis={visible} handleClick={onArrowClick}/>

                <PageSection
                    isClipped={true}
                    background={colors.dark}
                    padding={width <= 1024 ? (width <= 576 ? '8vh 30px' : '0 30px') : '0 30px 0px'}
                >
                    <AnimatePresence>
                        {activeElement === 2 && (
                            <>
                                <SlideInElement top={120} left={-180} bg={bgLightElement} variant={fadeInLeft} />
                                <SlideInElement
                                    top={width > 1024 ? 220 : null}
                                    bottom={width <= 1024 ? 125 : null}
                                    right={width > 1024 ? -165 : null}
                                    left={width <= 1024 ? 190 : null}
                                    bg={bgLightElement}
                                    variant={fadeInUp}
                                />
                                {width >= 576 ? (
                                    <motion.div
                                        exit="exit"
                                        variants={fadeInUp}
                                        initial="initial"
                                        animate="animate"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                            justifyContent: 'space-between',
                                            position: 'relative',
                                            zIndex: '1',
                                        }}
                                    >
                                        <div>
                                            <SubTitle style={{ maxWidth: '616px' }}>
                                                Unparalleled underwriting and marketing support
                                            </SubTitle>
                                            <TagLine>{`${width > 560 ? tagLineText1 : tagLineText2}`}</TagLine>
                                        </div>
                                        <FormContainer isDepartmentForm={true} />
                                    </motion.div>
                                ) : (
                                    <Scroll
                                        activeElement={activeElement}
                                        lastElement={lastElement}
                                        handleChangeSlide={handleChangeSlide}
                                    >
                                        <div>
                                            <SubTitle style={{ maxWidth: '616px' }}>
                                                Unparalleled underwriting and marketing support
                                            </SubTitle>
                                            <TagLine>{`${width > 560 ? tagLineText1 : tagLineText2}`}</TagLine>
                                        </div>
                                        <FormContainer isDepartmentForm={true} />
                                    </Scroll>
                                )}
                                <Arrow icon={arrowUp} style={{ transform: 'rotate(180deg)' }} onClick={() => onArrowClick(3, 0)}/>
                            </>
                        )}
                    </AnimatePresence>
                </PageSection>
                
                <SlideProducts isActive={activeElement === 3} handleClick={onArrowClick} />
                <SlideFooter isClipped={true} isActive={activeElement === 4} handleClick={() => onArrowClick(0, 4)} />
            </Slider>

            <LottieOuter maxWidth={1260} >
                <LottieInner className={'bodymovin'} ref={lottieContainer} />
            </LottieOuter>
        </Layout>
    );
};

export default IndexPage;
