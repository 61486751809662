import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const Btn = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    outline: none;
    background: transparent;
    user-select: none;
    cursor: pointer;

    &:active, &:focus {
        outline: none;
    }
`
const strokeColor = "hsl(0, 0%, 18%)";
const Path = (props) => {
    return (
        <motion.path
            fill="transparent"
            strokeWidth="3"
            stroke={props.color || strokeColor}
            strokeLinecap="round"
            {...props}
        />
    )
};

export const BurgerMenu = ({ toggle, color}) => {
    return (
        <Btn onClick={toggle}>
            <svg width="22" height="19" viewBox="0 0 22 19">
                <Path
                    color={color}
                    variants={{
                        closed: { d: "M 2 2.5 L 20 2.5" },
                        open: { d: "M 3 16.5 L 17 2.5" }
                    }}
                />
                <Path
                    color={color}
                    d="M 2 9.423 L 20 9.423"
                    variants={{
                        closed: { opacity: 1 },
                        open: { opacity: 0 }
                    }}
                    transition={{ duration: 0.1 }}
                />
                <Path
                    color={color}
                    variants={{
                        closed: { d: "M 2 16.346 L 20 16.346" },
                        open: { d: "M 3 2.5 L 17 16.346" }
                    }}
                />
            </svg>
        </Btn>
    )
};
