import styled from "styled-components";

export const LottieOuter = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  max-width: 1260px;
  max-height: 400px;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: ${props => props.zIndex ? 10 : 1};
`;

export const LottieInner = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  width: 600px;
  height: 600px;
  z-index: 10;
  pointer-events: none;
  transform: translateY(-50%);
  @media (max-width: 1200px) {
    width: 560px;
    height: 560px;
    transform: translateY(-60%);
  }
  @media (max-width: 560px) {
    top: 163px;
    width: 315px;
    height: 315px;
  }
`;

export const Mock = styled(LottieInner)`
  opacity: 0;
  background: url("${props => props?.mockImg}") center center / cover no-repeat;
  
  &[data-vis="1"] {
    opacity: 1;
  }

  @media (max-width: 560px) {
    width: 315px;
    height: 315px;
    top: 163px;
    transform: translate(0);
  }
`;