const tagLineText1 = 'As an insurance wholesaler, AmSuisse is committed to providing you' +
    ' with superior coverage solutions tailored to your client’s needs. The hallmark of our' +
    ' business is the customer service excellence we provide our agent and broker partners.' +
    ' When you work with us, you can count on us to support you as an extension of your team with' +
    ' responsive proposals and quotes that help you achieve higher closing ratios on more profitable business.';

const tagLineText2 = 'AmSuisse is a closed wholesale insurance broker that partners with a select' +
    ' group of retail agencies and brokers. We offer a portfolio of insurance products from admitted' +
    ' to non-admitted carriers to respond to the needs of your clients. With us, you can quote multiple' +
    ' carriers and issue policies in minutes.';

export {tagLineText1, tagLineText2}
