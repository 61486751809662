export const fadeInUp = {
    initial: {
        y: 70,
        opacity: 0,
        clipPath: `polygon(0 0, 100% 0, 100% 0, 0 30%)`
    },
    exit: {
        y: 70,
        opacity: 0,
        clipPath: `polygon(0 0, 100% 0, 100% 0, 0 30%)`
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            delay: .2,
            duration: .5
        },
        clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
    }
}
export const formFadeInUp = {
    initial: {
        y: 20,
        opacity: 0,
    },
    exit: {
        y: 20,
        opacity: 0,
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            delay: .4,
            duration: .5
        },
    }
}
export const slideInUp = {
    initial: {
        y: 50,
        opacity: 0,
    },
    exit: {
        y: 50,
        opacity: 0,
    },
    open: {
        y: 0,
        opacity: 1,
        transition: {
            duration: .3
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            duration: .3
        }
    }
}
export const fadeInLeft = {
    initial: {
        opacity: 0,
        clipPath: `polygon(0 0, 0 0, 0 100%, 0 100%)`
    },
    exit: {
        opacity: 0,
        clipPath: `polygon(0 0, 0 0, 0 100%, 0 100%)`
    },
    animate: {
        opacity: 1,
        transition: {
            delay: .2,
            duration: .5
        },
        clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
    }
}
export const fadeInRight = {
    initial: {
        opacity: 0,
        clipPath: `polygon(100% 0, 100% 0, 100% 100%, 100% 100%)`
    },
    exit: {
        opacity: 0,
        clipPath: `polygon(100% 0, 100% 0, 100% 100%, 100% 100%)`
    },
    animate: {
        opacity: 1,
        transition: {
            duration: .5
        },
        clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
    }
}
export const titleFadeInUp = {
    initial: {
        y: 150,
    },
    exit: {
        y: 150,
    },
    animate: {
        y: 150,
        transition: {
            duration: .4
        },
    },
    hover: {
        y: 0,
        transition: {
            duration: .4
        },
    }
}
export const textFadeInUp = {
    initial: {
        y: 50,
        opacity: 0,
    },
    animate: {
        y: 50,
        opacity: 0,
        transition: {
            duration: .4
        },
    },
    exit: {
        y: 50,
        opacity: 0,
    },
    hover: {
        y: 0,
        opacity: 1,
        transition: {
            delay: .2,
            duration: .2
        },
    }
}
export const sidebar = {
    open: {
        width: "100vw",
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        transition: {
            duration: .3
        }
    },
    closed: {
        width: "0",
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        transition: {
            duration: .3,
            delay: .3
        }
    }
};
export const listAnimationVariant = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};
export const listItemAnimationVariant = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};