import styled from "styled-components";
import {colors} from "../style/style";

const Title = styled.h2`
  max-width: ${({maxWidth}) => maxWidth ? maxWidth + "px" : "unset"};
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 100px;
  line-height: 110px;
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: ${props => props.color || colors.light};

  &.about-us {
    @media (max-width: 767px) {
      font-size: 90px;
      line-height: 95px;
      max-width: unset;
    }
    @media (max-width: 690px) {
      font-size: 80px;
      line-height: 85px;
    }
    @media (max-width: 610px) {
      font-size: 50px;
      line-height: 55px;
    }
  }

  &.products {
    @media (max-width: 1200px) {
      font-size: 75px;
      line-height: 85px;
    }
    @media (max-width: 576px) {
      font-size: 40px;
      line-height: 46px;
    }
  }

  &.quoting {
    @media (max-width: 1200px) {
      font-size: 65px;
      line-height: 75px;
    }

    @media (max-width: 1024px) {
      font-size: 34px;
      line-height: 44px;
      max-width: 315px;
      margin-right: 50px;
    }
    @media (max-width: 767px) {
      margin-right: 0;
    }
    @media (max-width: 576px) {
      font-size: 38px;
      line-height: 44px;
    }
  }

  &.quoting-insure {
    @media (max-width: 1200px) {
      font-size: 65px;
      line-height: 75px;
    }
    @media (max-width: 1024px) {
      font-size: 44px;
      line-height: 54px;
      max-width: 315px;
    }
  }

  @media (max-width: 576px) {
    font-size: 56px;
    line-height: 60px;
    max-width: 315px;
  }
`;
const SubTitle = styled.h3`
  max-width: ${({maxWidth}) => maxWidth ? maxWidth + "px" : "unset"};
  margin: 0;
  font-size: 80px;
  line-height: 90px;
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: ${props => props.color || colors.light};

  @media (max-width: 1200px) {
    font-size: 75px;
    line-height: 85px;
  }
  @media screen and (max-width: 767px),
        screen and (max-height: 900px) {
    font-size: 56px;
    line-height: 60px;
    max-width: 550px;
  }
  @media (max-width: 560px) {
    font-size: 44px;
    line-height: 54px;
    max-width: 315px;
  }

  @media (max-height: 720px) {
    font-size: 34px;
    line-height: 44px;
    max-width: 315px;
  }
  
`
const TagLine = styled.p`
  margin: 30px 0 0 0;
  max-width: ${props => props?.maxWidth || 600}px;
  color: ${props => props.color || colors.light};
  font-family: "SF Pro Display", "Helvetica Neue", sans-serif;
  font-size: ${props => props.fsize || 18}px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  @media (max-width: 768px) {
    max-width: 550px;
  }
  @media (max-width: 560px) {
    font-size: 14px;
    line-height: 20px;
    max-width: 315px;
  }
`
const Outline = styled.span`
  background: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px ${props => props?.color || colors.dark};
  -webkit-box-decoration-break: clone;
`;

export {Title, SubTitle, TagLine, Outline}
