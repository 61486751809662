import React, { useState } from "react";

import styled from "styled-components";
import mail from "../static/images/icons/mail.svg";
import useWindowDimensions from "../utils/hooks/useWindowWidth";
import FormPopup from "./Form/FormPopup";

const MailIcon = styled.div`
  width: 65px;
  height: 65px;
  cursor: pointer;
  background: url(${mail}) center center no-repeat;
  background-color: #2a2a2a;
  opacity: 0.7;
  border-radius: 50%;
  transition: opacity 0.3s ease-in-out;

  position: ${(props) => (props.position ? props.position : "absolute")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : 999)};
  top: ${(props) => (props.top ? props.top : 630)}px;

  &:hover {
    opacity: 1;
  }
`;

const MailButton = ({
  zIndex,
  align = "right",
  alignValue = 0,
  position = "absolute",
  marginTopFromRelative = 200,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { height, width } = useWindowDimensions();

  const top = height - marginTopFromRelative;

  if (!height || !width) return null;

  return (
    <>
      {width >= 1200 && (
        <>
          <MailIcon
            zIndex={zIndex}
            position={position}
            positionValue={alignValue}
            top={top}
            onClick={() => setOpen(true)}
            style={{ [align]: alignValue }}
          />
          <FormPopup
            isDepartmentForm={true}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default MailButton;
