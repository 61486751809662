import React from 'react';
import {HelmetDatoCms} from 'gatsby-source-datocms';
import {useStaticQuery, graphql} from 'gatsby';

import '../fonts/fonts.css';
import './layout.css';

import SFProDisplayBold from '../fonts/SFProDisplay/SFProDisplay-Bold.woff2';
import SFProDisplayMedium from '../fonts/SFProDisplay/SFProDisplay-Medium.woff2';
import PoppinsBold from '../fonts/Poppins/Poppins-Bold.woff';

export default function Layout(props) {
    const {seo, site} = useStaticQuery(graphql`
        query LayoutQuery {
            site: datoCmsSite {
                favicon: faviconMetaTags {
                    ...GatsbyDatoCmsFaviconMetaTags
                }
            }
            seo: datoCmsSeo(slug: {eq: "default-2349826387"}) {
                meta: seoMetaTags {
                    tags
                }
            }
        }
    `);

    return <React.Fragment>
        <HelmetDatoCms seo={seo?.meta} favicon={site?.favicon}>
            <html lang="en" />
            <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href={SFProDisplayBold}/>
            <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href={SFProDisplayMedium}/>
            <link rel="preload" as="font" type="font/woff" crossOrigin="anonymous" href={PoppinsBold}/>
            <meta HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8" />
            <script src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`} async defer></script>
            <script>
                {   
                    (function() {
                        const isBrowser = typeof window !== "undefined"

                        if (isBrowser) {
                            document.addEventListener("DOMContentLoaded", function(event) {
                                function timestamp() {
                                    let response = document.getElementById("g-recaptcha-response");
                                    /* eslint-disable-next-line */
                                    if (response == null || response.value.trim() == "") {
                                        var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);
                                        elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems);
                                    } 
                                }
                                setInterval(timestamp, 500);
                            })
                        }
                    })()
                }
            </script>
        </HelmetDatoCms>
        {props.children}
    </React.Fragment>
}
