import React from "react";
import styled from "styled-components";

const Section = styled.section`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0%;
    top: ${({isClipped}) => isClipped ? "-215px" : "0"};
    right: 0%;
    bottom: ${({isClipped}) => isClipped ? "-215px" : "0"};
    z-index: -1;
    background: ${({background}) => background} no-repeat center/cover;
    transition: clip-path 0.1s ease;
    clip-path: polygon(0 ${({isClipped}) => isClipped ? "12%" : "0"}, 100% 0, 100% ${({isClipped}) => isClipped ? "88%" : "100%"}, 0 100%, 0 100%);
  }

  @media all and (max-width: 768px) {
    &:after {
        top: ${({isClipped}) => isClipped ? "-100px" : "0"};
        bottom: ${({isClipped}) => isClipped ? "-100px" : "0"};
        clip-path: polygon(0 ${({isClipped}) => isClipped ? "8%" : "0"}, 100% 0, 100% ${({isClipped}) => isClipped ? "92%" : "100%"}, 0 100%, 0 100%);
    }
  }
  @media all and (max-width: 560px) {
    &:after {
        clip-path: polygon(0 ${({isClipped}) => isClipped ? "6%" : "0"}, 100% 0, 100% ${({isClipped}) => isClipped ? "94%" : "100%"}, 0 100%, 0 100%);
    }
  }

  @media all and (max-height: 769px) {
    &:after {
        top: ${({isClipped}) => isClipped ? "-100px" : "0"};
        bottom: ${({isClipped}) => isClipped ? "-100px" : "0"};
        clip-path: polygon(0 ${({isClipped}) => isClipped ? "8%" : "0"}, 100% 0, 100% ${({isClipped}) => isClipped ? "92%" : "100%"}, 0 100%, 0 100%);
    }
  }
}
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: ${({height}) => height || "100vh"};
  max-height: ${({maxHeight}) => maxHeight || "100vh"};
  max-width: ${({maxWidth}) => maxWidth || "1260px"};
  margin: 0 auto;
  padding: ${({padding}) => padding || "30px"};
  
  @media all and (min-width: 768px) {
    padding: 100px 30px;
  }

  @media (max-width: 1200px) {
    max-width: ${({maxWidth1200}) => maxWidth1200 || '1104px'};
  }

  @media (max-width: 767px) {
    justify-content: ${({align}) => align || "flex-end"};
  }

  @media (max-width: 768px) {
    height: 100%;
    max-height: fit-content;
    min-height: 100vh;
  }

  @media all and (max-height: 450px) {
    justify-content: ${({align450H}) => align450H || ''};
  }

  @media all and (max-height: 768px) {
    height: 100%;
    max-height: fit-content;
    min-height: 100vh;
  }
`

const PageSection = (props) => {
    return (
        <Section isClipped={props.isClipped} background={props.background}>
            {props.withoutContainer ?
                <>{props.children}</> :
                <Container
                    maxHeight={props.maxHeight}
                    padding={props.padding}
                    padding1200={props.padding1200}
                    padding767={props.padding767}
                    align={props.align}
                    height={props.height}
                    align450H={props.align450H}
                >{props.children}</Container>}
        </Section>
    )
}

export default PageSection
