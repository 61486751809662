import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {colors} from "../../style/style";
import {swiperScrollOptions} from "../../pages";
import {Title} from "../PageParts";
import {motion} from "framer-motion";
import {graphql, useStaticQuery} from "gatsby";
import SwiperCore, {Mousewheel} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import closeBt from '../../static/images/icons/icon_close.svg';
import bgDarkElement from "../../static/images/bg-element-dark.svg"
import TransitionLink from 'gatsby-plugin-transition-link';

SwiperCore.use([Mousewheel]);

const productsQuery = graphql`{
    allDatoCmsProduct(sort: {fields: position, order: ASC}) {
        nodes {
            id
            slug
            title
            cover {
                url(imgixParams: {w: "1440"})
            }
        }
    }
}`;

const BgElement = styled(motion.div)`
  position: absolute;
  top: 190px;
  left: -120px;
  width: 460px;
  height: 260px;
  background: url(${props => props.bg}) repeat;
  z-index: 100;
`;
const ProductsNav = styled(motion.div)`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px;
  z-index: 100;
`;
const ProductsNavSwiper = styled(Swiper)`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  width: 100%;
  z-index: 200;

  .swiper-wrapper {
    padding-right: calc(100% - 703px);
  }

  .swiper-slide {
    max-height: 230px;
    transform: translate3d(0, 0, 0);
  }
`;
const ProductsNavImage = styled(motion.div)`
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  background: ${props => props.background};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%);

  .swiper-slide-active &,
  &:hover {
    filter: grayscale(0);
  }
`;
const ProductsBg = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background: ${colors.light};
  z-index: 100;
`;
const ProductsWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  height: 100vh;
  overflow: hidden;
`;
const CloseBtn = styled(motion.button)`
  position: absolute;
  top: 50px;
  right: 0;
  width: 22px;
  height: 22px;
  z-index: 1000;
  border: none;
  background: url(${closeBt}) center center no-repeat;
  visibility: ${({isOpen}) => isOpen ? "visible" : "hidden"};
  pointer-events: ${({isOpen}) => isOpen ? "all" : "none"};
  cursor: pointer;

  &:active {
    outline: none;
  }
`;
const PaginationContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  right: -60px;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 400px;
  z-index: 900;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;
`
const PaginationLink = styled(TransitionLink)`
  display: inline-flex;
  align-items: center;
  max-width: 350px;
  padding: 10px 0;
  font-size: 20px;
  line-height: 33px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  color: ${colors.dark};
  opacity: .4;

  div {
    display: inline-block;
    margin-right: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  span {
    transition: font-size 100ms ease;
  }

  &:hover,
  &.active {
    text-decoration: none;
    opacity: 1;
    color: ${colors.dark};

    span {
      font-size: 28px;
      font-weight: bold;
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
const productsListAnimationVariant = {
    open: {
        opacity: 1,
        transition: {staggerChildren: 0.07, delayChildren: 0.2}
    },
    closed: {
        opacity: 0,
        transition: {staggerChildren: 0.05, staggerDirection: -1}
    }
};
const swiperSlideAnimation = {
    open: i => ({
        opacity: 1,
        clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 100%)`,
        transition: {
            delay: i * 0.2,
            duration: .5
        },
    }),
    closed: {
        opacity: 0,
        clipPath: `polygon(0 0, 0 0, 0 100%, 0 100%)`
    },

};
const fadeInUpTitle = {
    closed: {
        y: 40,
        opacity: 0,
        clipPath: `polygon(0 0, 100% 0, 100% 0, 0 30%)`,
        transition: {stiffness: 1000}
    },
    open: {
        y: 0,
        opacity: 1,
        transition: {
            duration: .4,
            y: {stiffness: 1000, velocity: -100}
        },
        clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
    }
};
const slideInLeft = {
    open: {
        opacity: 1,
        transition: {
            duration: .5
        },
        clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
    },
    closed: {
        opacity: 0,
        clipPath: `polygon(0 0, 0 0, 0 100%, 0 100%)`
    }
};
const productsBg = {
    open: {
        width: "100vw",
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        transition: {
            duration: .2
        }
    },
    closed: {
        width: "0",
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        transition: {
            duration: .3,
            delay: .3
        }
    }
};

export const ProductsNavSlider = ({slideToProduct, isOpen, toggleOpen}) => {
    const [swiper, setSwiper] = useState();
    const {allDatoCmsProduct: {nodes: products}} = useStaticQuery(productsQuery);
    const pointerEvents = isOpen ? "all" : "none";

    const onMouseEnter = (i) => {
        const activePagination = document.querySelector('.active')
        const pagination = document.querySelectorAll('.pagination')
        if (activePagination) activePagination.classList.remove('active')
        pagination[i].classList.add("active")
    }
    const onMouseLeave = () => {
        const activePagination = document.querySelector('.active')
        if (activePagination) activePagination.classList.remove('active')
    }
    useEffect(() => {
        if (isOpen) {
            document.body.style.maxHeight = "100vh"
            document.body.style.overflow = "hidden"
        }
        return () => {
            document.body.removeAttribute("style")
        }
    })

    return (
        <ProductsWrapper
            initial={false}
            animate={isOpen ? "open" : "closed"}
            style={{pointerEvents: pointerEvents}}
        >
            <ProductsBg variants={productsBg}/>
            <ProductsNav
                variants={productsListAnimationVariant}
                style={{pointerEvents}}
            >
                <motion.div variants={fadeInUpTitle} style={{marginBottom: "80px"}}>
                    <Title color={colors.dark}>Our<br/> Products</Title>
                </motion.div>
                <BgElement bg={bgDarkElement} variants={slideInLeft}/>
                <CloseBtn variants={{open: {opacity: 1}, closed: {opacity: 0}}} onClick={toggleOpen} isOpen={isOpen}/>
                <PaginationContainer className={"pagination-container"} variants={fadeInUpTitle}>
                    {
                        products.map(({title, slug, id}, i) =>
                            <PaginationLink
                                className={"pagination"}
                                key={id}
                                to={`/product-details`}
                                onClick={() => {
                                    toggleOpen();
                                    slideToProduct && slideToProduct(i)
                                }}
                                onMouseEnter={() => {
                                    swiper.slideTo(i)
                                }}
                                onMouseLeave={() => {
                                    const activeSlide = document.querySelector(".swiper-slide-active")
                                    if (activeSlide) activeSlide.classList.remove('swiper-slide-active')
                                }}
                                entry={{
                                    state: {hash: slug}
                                }}
                                exit={{
                                    state: {hash: slug}
                                }}
                            >
                                <div>{`${(i + 1) < 10 ? "0" + (i + 1) : i + 1}`}</div>
                                <span>{title}</span>
                            </PaginationLink>
                        )
                    }
                </PaginationContainer>
                <ProductsNavSwiper
                    onSwiper={(s) => {
                        setSwiper(s)
                        const activeSlide = document.querySelector(".swiper-slide-active")
                        if (activeSlide) activeSlide.classList.remove('swiper-slide-active')
                    }}
                    speed={500}
                    mousewheel={swiperScrollOptions}
                    simulateTouch={false}
                    direction={"vertical"}
                    spaceBetween={15}
                    slidesPerView={'auto'}
                    style={{height: '100vh'}}
                >
                    {products.map(({id, cover, slug}, i) =>
                        <SwiperSlide key={id}>
                            <TransitionLink
                                to={`/product-details`}
                                onClick={() => {
                                    toggleOpen();
                                    slideToProduct && slideToProduct(i)
                                }}
                                onMouseEnter={onMouseEnter.bind(null, i)}
                                onMouseLeave={onMouseLeave}
                                entry={{
                                    state: {hash: slug}
                                }}
                                exit={{
                                    state: {hash: slug}
                                }}
                            >
                                <ProductsNavImage custom={i} variants={swiperSlideAnimation} background={`url(${cover.url})`}/>
                            </TransitionLink>
                        </SwiperSlide>
                    )}
                    <SwiperSlide/>
                    <SwiperSlide/>
                    <SwiperSlide/>
                </ProductsNavSwiper>
            </ProductsNav>
        </ProductsWrapper>
    )
}
