import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import * as css from './slides.module.css';
import { fadeInRight, fadeInUp } from '../../style/motion-variants';

import SlideInElement from '../SlideInElement/SlideInElement';
import ProductsList from '../ProductList';

import bgDarkElement from '../../static/images/bg-element-dark.svg';

import Arrow from '../Arrow'
import arrowDown from "../../static/images/icons/arrow_down.svg";

function SlideProducts({ isActive,handleClick }) {
    const goToSlide = (number) => {
        handleClick(number)
    }
    return (
        <section className={`${css.whiteSection}`}>
            <div  className={`${css.wrapper} ${css.wrapperFullWidth} ${css.productsWrapper}`}  >
                <AnimatePresence>
                    {isActive && (
                        <>
                            <SlideInElement top={120} right={-20} bg={bgDarkElement} variant={fadeInRight} />
                            <motion.div
                                exit="exit"
                                variants={fadeInUp}
                                initial="initial"
                                animate="animate"
                                className={css.titleFullWidth}
                            >
                                <h2 className={css.title}>Our Products</h2>
                            </motion.div>
                            <ProductsList />
                            <SlideInElement bottom={97} left={-172} bg={bgDarkElement} variant={fadeInRight} />
                            {typeof handleClick === "function" ? <Arrow icon={arrowDown} onClick={() => goToSlide(4)}/> : null}
                        </>
                    )}
                </AnimatePresence>
            </div>
        </section>
    );
}

export default SlideProducts;
