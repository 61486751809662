import React from 'react'
import styled from "styled-components";
import { motion } from "framer-motion";

const BgElement = styled(motion.div)`
  position: absolute;
  top: ${props => props.top ? props.top + 'px' : 'auto'};
  left: ${props => props.left ? props.left + 'px' : 'auto'};
  right: ${props => props.right ? props.right + 'px' : 'auto'};
  bottom: ${props => props.bottom ? props.bottom + 'px' : 'auto'};
  width: 460px;
  height: 260px;
  background: url(${props => props.bg}) repeat;
  transform: translate3d(0,0,0);
  pointer-events: none;
  z-index: 1;
`
const SlideInElement = ({top,left,right,bottom,bg,variant}) => {
    return (
        <BgElement
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            bg={bg}
            variants={variant}
            exit={{opacity: 1}}
            initial={"initial"}
            animate={"animate"}/>
    )
}

export default SlideInElement
