import React from "react";
import {AnimatePresence, motion} from "framer-motion";

import SlideInElement from "./SlideInElement/SlideInElement";
import {PageFooter} from "./PageFooter";
import Arrow from "./Arrow";

import {fadeInLeft, fadeInUp} from "../style/motion-variants";
import {colors} from "../style/style";
import bgLightElement from "../static/images/bg-element-light.svg";
import arrowUp from "../static/images/icons/arrow_up.svg";
import styled from "styled-components";
import useWindowDimensions from "../utils/hooks/useWindowWidth";

const FooterSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 30px 30px 120px 30px;

  &:after {
    content: '';
    position: absolute;
    left: 0%;
    top: ${({isClipped}) => isClipped ? "-200px" : "0"};
    right: 0%;
    bottom: 0;
    z-index: -1;
    background: ${colors.dark} no-repeat center/cover;
    transition: clip-path 0.1s ease;
    clip-path: polygon(0 ${({isClipped}) => isClipped ? "12%" : "0"}, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  @media all and (max-width: 768px) {
    &:after {
      top: ${({isClipped}) => isClipped ? "-100px" : "0"};
      clip-path: polygon(0 ${({isClipped}) => isClipped ? "8%" : "0"}, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
  }

  @media all and (max-width: 560px) {
    &:after {
      clip-path: polygon(0 ${({isClipped}) => isClipped ? "6%" : "0"}, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
  }
  
  @media all and (min-width: 768px) {
    padding: 150px 30px 138px;
  }
  
  @media all and (max-height: 730px) {
    padding: 30px;
  }

  @media all and (max-width: 320px) {
    padding: 70px 30px;
  }
`;

function SlideFooter({
    handleClick,
    isActive = true,
    inELement = {top: 120, left: -180}, // -20 -180
    isClipped = false
}) {
    const { width } = useWindowDimensions();
    return <FooterSection isClipped={isClipped}>
        <AnimatePresence>
            {isActive &&
            <React.Fragment>
                <SlideInElement {...inELement} bg={bgLightElement} variant={fadeInLeft}/>
                <motion.div
                    style={{
                        display: 'flex',
                        alignItems: width >= 768 ? 'center' : 'flex-end',
                        flex: '1',
                        margin: '0 auto'
                    }}
                    exit="exit" variants={fadeInUp} initial="initial" animate="animate"
                >
                    <PageFooter/>
                </motion.div>
                {typeof handleClick === "function" ? <Arrow icon={arrowUp} onClick={handleClick}/> : null}
            </React.Fragment>}
        </AnimatePresence>
    </FooterSection>
}

export default SlideFooter;
