import {useState, useEffect} from "react";
import ReactDOM from "react-dom";

const portalRoot = typeof document !== `undefined` ? document.createElement("div") : null

const Portal = ({children}) => {
    const [container] = useState(portalRoot);
    useEffect(() => {
        if(portalRoot) {
            document.body.appendChild(container);
            return () => document.body.removeChild(container);
        }
    }, [container]);
    return ReactDOM.createPortal(children, container);
}

export default Portal;