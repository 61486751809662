import React from 'react';

const Slide = ({ children, classes, zIndex }) => {
    return (
        <div style={{ zIndex }} className={classes}>
            {children}
        </div>
    );
};

export default Slide;
