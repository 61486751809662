import * as React from "react";
import styled from "styled-components";
import { colors } from "../style/style";
import footerLogo from "../static/images/footer-logo.svg";
import footerSponsorsLogo1 from "../static/images/wsia.png";
import footerSponsorsLogo2 from "../static/images/tsla.png";
import {fadeInUp} from "../style/motion-variants";
import {motion} from "framer-motion";


const Footer = styled(motion.footer)`
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  p {
    color: ${colors.light};
  }
`;

const FooterLogo = styled.div`
  width: 100%;

  img {
    display: block;
    margin: 0 0 10px;
    max-width: 100%;
  }
`;

const FooterBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;

  a {
    text-decoration: none;
    transition: color .2s;

    &:hover {
      color: ${colors.orange};
    }
  }
`;

const SponsorsLogo = styled.div`
  margin: 120px 0 0;

  @media (max-width: 767px) {
    width: 100%;
    margin: 20px 0 0;
  }

  img {
    display: block;
    margin: 0 0 30px;
  }
`;

const Contacts = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  margin: 20px 0 0;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 40px 0 20px;

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    margin: 0 0 0 20px;

    &:visited {
      color: white;
    }

    &:hover {
      color: ${colors.orange};
    }
  }

  img {
    display: block;
    margin: 0 0 10px;
  }
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;

  span, a {
    color: ${colors.light};
    font-size: 14px;
    line-height: 24px;

    &:hover {
      text-decoration: none;
    }
  }
`

export const PageFooter = (props) => {
  const date = new Date().getFullYear()  
	return (
		<Footer exit="exit" variants={ fadeInUp } initial="initial" animate="animate">
			<FooterLogo>
				<img src={footerLogo} alt="AmSuisse" style={ { margin: "0" } } />
			</FooterLogo>
			<FooterBlock>
				<SponsorsLogo>
          <img src={footerSponsorsLogo1} width="141" height="47" alt="WSIA logo" />
          <img src={footerSponsorsLogo2} width="185" height="47" alt="TSLA logo" />
				</SponsorsLogo>
				<Contacts>
					<FooterInfo>
						<span>AmSuisse</span>
						<span>1400 Ravello Drive</span>
						<span>Ste. N150</span>
						<span>Katy TX 77449</span>
						<a href="tel:(800)485-0229">(800) 485-0229</a>
					</FooterInfo>
					<SocialIcons>
						<a href="https://twitter.com/amsuisseins?lang=en" target="_blank" rel="noopener noreferrer">
							<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M21.9569 4.20123C21.1333 4.57602 20.2607 4.82341 19.3673 4.93545C20.3079 4.35116 21.0122 3.43485 21.3501 2.35574C20.4783 2.88154 19.5122 3.26428 18.4837 3.47744C17.8051 2.72752 16.906 2.23014 15.9258 2.06253C14.9457 1.89492 13.9395 2.06646 13.0634 2.55052C12.1872 3.03457 11.4903 3.80405 11.0807 4.73946C10.6711 5.67487 10.5719 6.72388 10.7983 7.72358C7.04917 7.54073 3.72808 5.67914 1.50333 2.86733C1.09889 3.57768 0.887942 4.38789 0.892833 5.21208C0.892833 6.8321 1.69033 8.256 2.8985 9.09254C2.18245 9.06898 1.48222 8.86889 0.856167 8.50895V8.56579C0.855761 9.64238 1.21575 10.686 1.87505 11.5195C2.53435 12.353 3.45236 12.9251 4.47333 13.1388C3.81177 13.322 3.11888 13.3495 2.44567 13.2193C2.73545 14.1458 3.29786 14.9558 4.05443 15.5361C4.811 16.1164 5.72399 16.4382 6.666 16.4565C5.07057 17.7506 3.1006 18.4529 1.0725 18.4507C0.715 18.4507 0.358417 18.4289 0 18.3872C2.06773 19.7556 4.47197 20.4819 6.92725 20.48C15.2258 20.48 19.7588 13.3785 19.7588 7.23094C19.7588 7.03199 19.7588 6.83304 19.745 6.63409C20.6305 5.97554 21.3943 5.15791 22 4.22018L21.9569 4.20123Z" fill="currentColor" />
							</svg>
						</a>
						<a href="https://www.facebook.com/AmSuisseIns/" target="_blank" rel="noopener noreferrer">
							<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M22 11.0669C22 4.95519 17.0748 0 11 0C4.92525 0 0 4.95519 0 11.0669C0 16.5911 4.02233 21.1691 9.28125 21.9991V14.2661H6.48817V11.0659H9.28125V8.62939C9.28125 5.85622 10.9239 4.32345 13.4365 4.32345C14.6392 4.32345 15.8987 4.54018 15.8987 4.54018V7.26355H14.5108C13.1441 7.26355 12.7178 8.11662 12.7178 8.99183V11.0669H15.7685L15.2808 14.267H12.7178V22C17.9777 21.1691 22 16.5901 22 11.0669Z" fill="currentColor" />
							</svg>
						</a>
						<a href="https://www.youtube.com/channel/UCH3bkDOZdErW9sF69GIsG-A" target="_blank" rel="noopener noreferrer">
							<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M21.5409 5.44594C21.4134 4.97518 21.1701 4.54626 20.8347 4.20066C20.4992 3.85507 20.0829 3.60449 19.6259 3.47314C17.9118 3.00001 11.0093 3.00001 11.0093 3.00001C11.0093 3.00001 4.12608 2.99057 2.39359 3.47314C1.9367 3.60471 1.52048 3.8555 1.18519 4.20126C0.849894 4.54702 0.606864 4.97606 0.479594 5.44689C0.154371 7.25519 -0.0061318 9.09085 0.000179079 10.9299C-0.00493456 12.7624 0.155563 14.5913 0.479594 16.3931C0.607001 16.8638 0.850093 17.2926 1.18538 17.6382C1.52066 17.9838 1.9368 18.2344 2.39359 18.3659C4.10683 18.84 11.0102 18.84 11.0102 18.84C11.0102 18.84 17.8925 18.84 19.6259 18.3659C20.083 18.2345 20.4994 17.9838 20.8349 17.638C21.1703 17.2922 21.4135 16.8631 21.5409 16.3922C21.8578 14.59 22.0112 12.7614 21.9992 10.9299C22.0126 9.0909 21.8591 7.25461 21.5409 5.445V5.44594ZM8.80748 14.3231V7.52734L14.5513 10.9309L8.80748 14.3231Z" fill="currentColor" />
							</svg>
						</a>
						<a href="https://www.linkedin.com/company/amsuisse/" target="_blank" rel="noopener noreferrer">
							<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M18.0392 18.0433H15.0775V13.4025C15.0775 12.2958 15.055 10.8717 13.5342 10.8717C11.99 10.8717 11.7542 12.0758 11.7542 13.3208V18.0433H8.7925V8.5H11.6375V9.80083H11.6758C12.0733 9.05083 13.04 8.25917 14.4842 8.25917C17.485 8.25917 18.04 10.2342 18.04 12.805V18.0433H18.0392ZM5.4475 7.19417C4.49417 7.19417 3.72833 6.4225 3.72833 5.47333C3.72833 4.525 4.495 3.75417 5.4475 3.75417C6.3975 3.75417 7.1675 4.525 7.1675 5.47333C7.1675 6.4225 6.39667 7.19417 5.4475 7.19417ZM6.9325 18.0433H3.9625V8.5H6.9325V18.0433ZM19.5208 1H2.47583C1.66 1 1 1.645 1 2.44083V19.5592C1 20.3558 1.66 21 2.47583 21H19.5183C20.3333 21 21 20.3558 21 19.5592V2.44083C21 1.645 20.3333 1 19.5183 1H19.5208Z" fill="currentColor" />
							</svg>
						</a>
					</SocialIcons>
					<p style={ { fontSize: "14px" } }>Copyright © {date} AmSuisse. All rights reserved.</p>
				</Contacts>
			</FooterBlock>
		</Footer>
	)
}
