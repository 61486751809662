import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Input, Underline } from "./Form/Form";
import { colors } from "../style/style";

import searchIcon from "../static/images/icons/search_icon.svg"

export const Search = styled.div`
  position: relative;
  max-width: 350px;
  text-align: left;

  &.form {
    margin: 0 !important;
    max-width: unset;
  }

  @media (max-width: 1200px) {
    margin: 40px 0 0 0;
    &.form {
      margin: 0 !important;
      max-width: unset;
    }
  }
  @media (max-width: 1124px) {
    margin: 40px 0 92px 0;
    &.form {
      margin: 0 !important;
      max-width: unset;
    }
  }
  @media (max-width: 900px) {
    margin: 30px 0 90px 0;

    &.form {
      margin: 0 !important;
      max-width: unset;
    }
  }
  @media (max-width: 560px) {
    margin: 30px 0 45px 0;
  }
`
export const DropDown = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  max-height: ${ props => props.maxHeight || "300px" };
  width: 100%;
  padding: 0 15px;
  background: ${ colors.light };
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 20px 13px rgba(0, 0, 0, 0.035), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
  overflow: scroll;
  z-index: 300;
`
export const DropDownItem = styled.div`
  padding: 5px 0;
  margin: 10px 0;
  cursor: pointer;

  span {
    pointer-events: none;
  }
`

const SearchBar = (props) => {
    const { setState, placeholder, icon, isRequired, states } = props;
    const [ search, setSearch ] = useState("");
    const [ display, setDisplay ] = useState(false);
    const options = states.sort((a, b) => a.name > b.name ? 1 : -1);
    const dropdownRef = useRef(null);
    const getHighlightedText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        if (highlight.length < 1) return text;
        return parts.map((part, i) => part === "" ? "" : (
                <span key={ i } style={ part.toLowerCase() === highlight.toLowerCase() ? { color: `${ colors.orange }` } : {} }>{ part }</span>
            )
        )
    }

    useEffect(() => {
        const onClickHandler = (e) => {
            const { current: wrap } = dropdownRef;
            if (wrap && !wrap.contains(e.target)) setDisplay(false)
        }
        document.addEventListener('click', onClickHandler)
        return () => {
            document.removeEventListener('click', onClickHandler)
        }
    })

    const onSearchItemClick = (e) => {
        if (!e.target.dataset.state) return false
        setSearch(e.target.textContent)
        setDisplay(false)
        typeof setState === "function" && setState(e.target.dataset.state.toLowerCase())
    }
    const onInputChange = (e) => {
        setDisplay(true)
        setSearch(e.target.value)
        const targetState = states.find(s => s.name.toLowerCase() === e.target.value.toLowerCase())
        if (targetState) {
            typeof setState === "function" && setState(targetState.state.toLowerCase())
            setDisplay(false)
        }
    }

    const variants = options.filter(({ name }) => name.toLowerCase().indexOf(search.toLowerCase()) > -1).map(op => (
        <DropDownItem key={ op.id } onClick={ onSearchItemClick } data-state={ op.state }>{ getHighlightedText(op.name, search) }</DropDownItem>
    ));

    return (
        <Search ref={ dropdownRef } className={props.className}>
            <Input type="text"
                  id="state_code"
                  onClick={ () => setDisplay(true) }
                  autoComplete="off"
                  value={ search }
                  onChange={ onInputChange }
                  color={ colors.dark }
                  required={ isRequired }
                  icon={ icon ? icon : searchIcon }
                  placeholder={ placeholder ? placeholder : "Check your state" }
                  borderColor={ 'rgba(42, 42, 42, 0.4)' }
            />
          <Underline/>
            {
                display &&
                <DropDown maxHeight={ props.maxHeight }>
                    { variants.length < 1 ? (
                        <DropDownItem>Nothing found...</DropDownItem>) : variants }
                </DropDown>
            }
        </Search>
    )
}

export default SearchBar;