import React from "react";
import {motion} from "framer-motion";

import * as css from './slides.module.css';

import {fadeInLeft, fadeInUp} from "../../style/motion-variants";

import {Outline} from "../PageParts";
import {Mock} from "../lottieParts";
import SlideInElement from "../SlideInElement/SlideInElement";

import Arrow from '../Arrow'
import bgDarkElement from "../../static/images/bg-element-dark.svg";
import heroMockImg from "../../static/images/mocks/hero-main.png";
import arrowDown from "../../static/images/icons/arrow_down.svg";

function SlideInMinutes({width, vis, handleClick}) {

    const goToSlide = (number) => {
        handleClick(number)
    }

    return <section className={css.whiteSection} >
        <div className={css.wrapper}>
            <SlideInElement
                top={width <= 1024 ? 100 : 120}
                left={width <= 1024 ? -330 : -400}
                bg={bgDarkElement}
                variant={fadeInLeft}/>
            <Mock mockImg={heroMockImg} data-vis={vis}/>
            <motion.div className={css.motiondiv} exit="exit" variants={fadeInUp} initial="initial" animate="animate">
                <h2 className={css.title}>
                    <span className={css.gradientText}>Quote</span>
                    <div>
                        <Outline>multiple </Outline>
                        <span className={css.gradientText}>
                            {width > 576 ? 'carriers and issue policies' : 'carriers and issue policies in'}
                        </span>
                    </div>
                    <Outline>{width > 576 ? 'in minutes' : 'minutes'}</Outline>
                </h2>
            </motion.div>

            {typeof handleClick === "function" ? <Arrow icon={arrowDown} onClick={() => goToSlide(1)}/> : null}

        </div>
    </section>
}

export default SlideInMinutes
