
    let lottieValue = 0;

    export const handleLottieTouch = (lottieContainer) => {
        if (lottieContainer.current) {
            const transformMatrix = window.getComputedStyle(lottieContainer.current).transform;
            const lottieTransformedValue = parseInt(transformMatrix.split(',')[5].trim());
            lottieValue = lottieTransformedValue;
            lottieContainer.current.style.transition = null;
            lottieContainer.current.style.transform = null;
        }
    };

    export const handleTransformLottie = (lottieContainer, e) => {
        if (lottieContainer) {
            lottieContainer.current.style.transform = `translateY(-${Math.abs(lottieValue + e)}px)`;
        }
    };

    export const handleLottieStop = (lottieContainer) => {
        lottieContainer.current.style.transition = `transform .3s ease-in`;
        lottieContainer.current.style.transform = `translateY(-${60}%)`;
    };

