import React from "react";
import {AnimatePresence, motion} from "framer-motion";

import * as css from './slides.module.css';

import {colors} from "../../style/style";
import {fadeInLeft, fadeInUp} from "../../style/motion-variants";

import Arrow from '../Arrow'
import {TagLine, Outline} from "../PageParts";
import {Mock} from "../lottieParts";
import SlideInElement from "../SlideInElement/SlideInElement";
import prodMockImg from "../../static/images/mocks/products-main.png";
import bgLightElement from "../../static/images/bg-element-light.svg";
import arrowUp from "../../static/images/icons/arrow_up.svg";
import {tagLineText1} from './Texts'

function SlideInsurance({isActive, vis, handleClick}) {
    const goToSlide = (number) => {
        handleClick(number)
    }
    return <section className={`${css.clipped} ${css.clippedGradient}`}>
        <div className={css.wrapper}>
            <AnimatePresence>
                {isActive && <>
                    <SlideInElement
                        top={120}
                        left={-100}
                        bg={bgLightElement}
                        variant={fadeInLeft}
                        exit="exit"
                    />
                    <motion.div
                        style={{position: 'relative', zIndex: 1000}}
                        exit="exit"
                        variants={fadeInUp}
                        initial="initial"
                        animate="animate"
                    >
                        <h2 className={css.title} style={{color: '#FFF'}}>Wholesale
                            <div>insurance
                                <Outline color={colors.light}> products</Outline>
                            </div>
                        </h2>
                        <TagLine>{tagLineText1}</TagLine>
                    </motion.div>
                </>}
            </AnimatePresence>
            <Mock mockImg={prodMockImg} data-vis={vis}/>
            {typeof handleClick === "function" ? <Arrow icon={arrowUp} style={{ transform: 'rotate(180deg)' }} onClick={() => goToSlide(2)}/> : null}
        </div>
    </section>
}

export default SlideInsurance