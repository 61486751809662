import React, { useRef, useEffect } from 'react';
import * as css from './slider.module.css';

const Scroll = ({ children }) => {
    const stateRef = useRef({
        containerHeight: 0,
        scrollableHeight: 0,
        containerTopPadding: 0,
        maxScrollHeight: 0,
    });
    let fullScrolled = false;
    let transitioned = 0;

    const scrollRef = useRef();
    const scrollContainer = useRef();

    let touchInitialPosition = 0;

    const handleTouchStart = (e) => {
        touchInitialPosition = e.changedTouches[0].clientY;
    };

    const handleTouchMove = (e) => {
        const currentPosition = e.changedTouches[0].clientY;
        const difference = currentPosition - touchInitialPosition;
        if (Math.abs(transitioned + difference) < stateRef.current.maxScrollHeight && transitioned + difference < 0) {
            scrollRef.current.style.transform = `translateY(${transitioned + difference}px)`;
            e.stopPropagation();
        } else {
            fullScrolled = true;
        }
    };

    const handleTouchEnd = (e) => {
        const transformMatrix = window.getComputedStyle(scrollRef.current).transform;
        const transformValue = parseInt(transformMatrix !== 'none' ? transformMatrix.split(',')[5].trim() : 0);
        transitioned = transformValue;
        if (!fullScrolled) {
            e.stopPropagation();
            return;
        }
    };

    useEffect(() => {
        stateRef.current.containerHeight = scrollContainer.current.clientHeight;
        stateRef.current.scrollableHeight = scrollRef.current.clientHeight;
        stateRef.current.containerTopPadding = parseInt(window.getComputedStyle(scrollContainer.current).paddingTop);

        stateRef.current.maxScrollHeight =
            stateRef.current.scrollableHeight -
            stateRef.current.containerHeight +
            stateRef.current.containerTopPadding + 200
    }, [children]);

    return (
        <div
            ref={scrollContainer}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            className={css.scrollContainer}
        >
            <div className={css.sliderRef} ref={scrollRef}>
                {children}
            </div>
        </div>
    );
};

export default Scroll;
