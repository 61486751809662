import React, { useEffect, useState } from "react"
import styled from "styled-components"
import {Link} from "gatsby"
import {colors} from "../../style/style";
import logo from "../../static/images/dark-logo.svg"
import lightLogo from "../../static/images/light-logo.svg"
import {useCycle} from "framer-motion";
import {BurgerMenu} from "../BurgerMenu/BurgerMenu";
import {ProductsNavSlider} from "../ProductsNav/ProductsNavSlider";
import {motion} from "framer-motion";
import Navigation from "./Navigation/Navigation";
import MailButton from "../MailButton";
import { useLocation } from "@reach/router";

const Header = styled.header`
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 900;
  @media (max-width: 1200px) {
    max-width: 1104px;
    padding: 0 50px;
  }
  @media (max-width: 767px) {
    padding: 0 30px;
  }
`;
export const NavLink = styled(Link)`
  color: ${props => props.color || colors.dark};
  text-decoration: none;
  transition: color .2s;

  &:hover {
    text-decoration: none;
    color: ${colors.orange};
  }
`;
const NavBtn = styled.a`
  color: ${props => props.color || colors.dark};
  text-decoration: none;
  transition: color .2s;

  &:hover {
    text-decoration: none;
    color: ${colors.orange};
    cursor: pointer;
  }
`
const Logo = styled.img`
  padding: 10px 0;
`;
const Nav = styled.nav`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;

  & a {
    padding: 13px 17px;

    &:not(:last-child) {
      margin-right: 1px;
    }

    &:last-of-type {
      margin-left: 18px;
    }
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;
export const StyledButton = styled(Link)`
  display: inline-block;
  min-height: 45px;
  min-width: 168px;
  padding: 11px 33px !important;
  border: 2px solid ${props => props.color};
  border-radius: 50px;
  color: ${props => props.color || colors.dark};
  font-size: 14px;
  font-weight: 700;
  background: transparent;
  transition: border .3s ease;
  text-decoration: none;
  text-align: center;

  &:hover {
    border: 2px solid ${colors.orange};
    text-decoration: none;
    color: ${props => props.color || colors.dark}
  }

  &:active, &:focus {
    outline: none;
    text-decoration: none;
  }
  
  &[data-screen="desktop"] {
    @media all and (max-width: 767px) {
      display: none;
    }
  }
  
  &[data-screen="mobile"] {
    @media all and (min-width: 768px) {
      display: none;
    }
  }
`;

const headerLinks = [
    {href: "/carriers/", title: "Carriers"},
    {href: "/about-us/", title: "About Us"},
    {href: "/faq/", title: "FAQ"}
]
const PageHeader = React.forwardRef((props, ref) => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const [isOpenProducts, toggleOpenProducts] = useCycle(false, true);
    const [display, setDisplay] = useState(true)
    const [ width, setWidth ] = useState(1201);
    const { pathname } = useLocation();
    useEffect(() => {
        setWidth(window.innerWidth);

        function changeWindowSize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", changeWindowSize);

        return () => {
            window.removeEventListener("resize", changeWindowSize);
        };
    }, []);

    useEffect(() => {
      const wrapper = document.querySelector('.tl-wrapper');
      if (wrapper && !isOpen) {
        wrapper.classList.add('overflow-hidden');
      }
      return () => {
        wrapper.classList.remove('overflow-hidden');
      }
    }, [isOpen])

    useEffect(() => {
      if (pathname === "/faq/") {
        setDisplay(false)
      } else if (
        pathname === "/online-quoting/"
      ) {
        setDisplay(false)
      }
    }, [pathname])

    return (
        <>
            <Header ref={ref} >
            {display && <MailButton />}
                <Link to={"/"}>
                    <Logo src={isOpen ? logo : props.color === colors.light ? lightLogo : logo} alt={"logo"}/>
                </Link>
                {width <= 1200 ?
                    <motion.div initial={false} animate={isOpen ? "open" : "closed"}>
                        <BurgerMenu color={isOpen ? colors.dark : props.color} toggle={toggleOpen}/>
                    </motion.div> :
                    <Nav>
                        <NavBtn color={props.color} onClick={toggleOpenProducts}>Products</NavBtn>
                        {headerLinks.map((link, index) => {
                            return <NavLink
                                color={props.color}
                                key={index + 1}
                                to={link.href}>
                                {link.title}
                            </NavLink>
                        })}
                        <StyledButton to={"/online-quoting/"} color={props.color}>Online quoting</StyledButton>
                    </Nav>
                }
            </Header>
            {width <= 1200 ?
                <Navigation toggleOpen={toggleOpen} isOpen={isOpen} links={headerLinks}/> :
                <ProductsNavSlider slideToProduct={props.slideToProduct} toggleOpen={toggleOpenProducts} isOpen={isOpenProducts}/>
            }
        </>
    )
})

export default PageHeader;
