import React, {useState} from "react";
import {useCycle} from "framer-motion";
import FormPopup from "./FormPopup";
import {FormBtn} from "../../pages";
import styled from "styled-components";
import {FormSubTitle} from "./Form";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 50px;
  background-color: #352B3E;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    right: 0;
    border-style: solid;
    border-width: 0 36px 28px 0;
    border-color: transparent #352B3E transparent transparent;
    margin-top: 0;
  }

  button:not(:last-child) {
    margin-bottom: 24px;
  }

  @media (max-width: 700px) {
    margin-top: 30px;
  }
`

const Title = styled.h3`
  margin-top: 32px; 
  text-align: right;
  font-family: Poppins;
  font-size: 36px;
  font-weight: bold;
  line-height: 54px;
  background: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1.5px white;
  -webkit-box-decoration-break: clone;
`

const Wrapper = styled.div`
  max-width: max-content;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
`

const FormContainer = ({isDepartmentForm}) => {
    const [isOpen, toggleForm] = useCycle(false, true);
    const [title, setTitle] = useState(null);
    const [type, setType] = useState(null);
    const clickHandler = (e) => {
        if (title !== e.target.textContent) {
          setTitle(e.target.textContent);
          setType(e.target.dataset.type);
        }
        toggleForm()
    }
    return (
      <Wrapper>
        <Container>
            <FormSubTitle style={{color: "#fff"}}>Choose department:</FormSubTitle>
            <FormBtn onClick={clickHandler} data-type={'General Information'}>Marketing & General Information</FormBtn>
            <FormBtn onClick={clickHandler} data-type={'Admitted Markets'}>Admitted Markets</FormBtn>
            <FormBtn onClick={clickHandler} data-type={'E&S'}>E&S and Specialty Markets</FormBtn>
            <FormPopup isDepartmentForm={isDepartmentForm} isOpen={isOpen} onClose={toggleForm} title={title} type={type} />
        </Container>
        <Title>Contact Us</Title>
      </Wrapper>
    )
}
export default FormContainer;