import { useEffect, useState } from 'react';

export default function useWindowDimensions() {
    const isBrowser = typeof window !== 'undefined';
    const [windowSize, setWindowSize] = useState(
        isBrowser ? { width: window.innerWidth, height: window.innerHeight } : { width: 0, height: 0 }
    );

    useEffect(() => {
        if (!isBrowser) return;

        const handleResize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return windowSize;
}
