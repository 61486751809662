import React, { useEffect, useRef, useCallback } from 'react';
import * as css from './slider.module.css';
import Slide from './Slide';
import useWindowDimensions from '../../utils/hooks/useWindowWidth';

const Slider = ({
    activeElement,
    children,
    lastElement,
    autoSlidePercent,
    handleChangeSlide,
    msAnimationTime,
    swipeDelayPercent,
    headerRef,
    lottieTransform,
    lottieTouch,
    lottieStop,
}) => {
    const sliderRef = useRef();

    let canSwipe = false;
    let backward = false;
    let scrollTouched = false;
    let isScrollComponent = false;
    let isProductListComponent = false;
    const touch = {
        x: 0,
        y: 0,
    };

    let recentEvents = [];
    let lastScrollTime = new Date().getTime();

    const childrenArray = React.Children.toArray(children);

    const notLast = activeElement < childrenArray.length - 1;
    const notFirst = activeElement > 0;

    const { height, width } = useWindowDimensions();

    const getPercentSize = (percent, dimension) => {
        return (percent / 100) * dimension;
    };

    const animateSwipe = (index, durationMs, transformValue, style) => {
        if (sliderRef.current) {
            sliderRef.current.children[index].style.transition = `transform ${durationMs}s ${style}`;
            sliderRef.current.children[index].style.transform = `translateY(${
                transformValue ? `-${transformValue}%` : 0
            })`;
        }
    };

    const resetTransform = (index) => {
        if (sliderRef.current) {
            sliderRef.current.children[index].style.transition = null;
        }
    };

    const isInPath = (e, className) => {
        let inPath = false;
        if (e.nativeEvent.composedPath()) {
            const path = e.nativeEvent.composedPath();
            for (let i = 0; i < path.length; i++) {
                if (path[i].classList && path[i].classList.value) {
                    const classes = path[i].classList.value.split('--');
                    if (classes.includes(className)) {
                        inPath = true;
                    }
                }
            }
        }
        return inPath;
    };

    const resetOpacity = useCallback(() => {
        if (headerRef.current) {
            headerRef.current.style.opacity = 1;
        }
    }, [headerRef]);

    const swipeTriggerDistance = getPercentSize(width < 768 ? autoSlidePercent * 1.2 : autoSlidePercent, height);
    const swipeDelay = getPercentSize(width < 768 ? swipeDelayPercent * 1.5 : swipeDelayPercent, height);

    const handleTouchStart = (e) => {
        if (e.touches.length < 1 || !isInPath(e,'sliderWrapper')) {
            return;
        }
        if (activeElement === 1 && lottieTouch) lottieTouch();
        isScrollComponent = isInPath(e, 'scrollContainer');
        isProductListComponent = isInPath(e, 'product-list-module');
        resetTransform(activeElement);
        if (activeElement > 0) resetTransform(activeElement - 1);
        canSwipe = true;
        touch.y = e.changedTouches[0].clientY;
        touch.x = e.changedTouches[0].clientX;
    };

    const handleTouchMove = (e) => {
        if (e.touches.length > 1 || !isInPath(e,'sliderWrapper')) {
            return;
        }
        if (activeElement === 1 && lottieTransform) lottieTransform(e);
        const newTouch = {
            x: e.changedTouches[0].clientX,
            y: e.changedTouches[0].clientY,
        };
        const swipedDistance = { x: touch.x - newTouch.x, y: newTouch.y - touch.y };
        if (width <= 375) {
            swipedDistance.y = swipedDistance.y * 1.3; // speedup swiping on mobile phones
        }
        const swipable = (swipedDistance.y < 0 && notLast) || (swipedDistance.y > 0 && notFirst);
        const canChangeSlide = swipedDistance.y < -swipeTriggerDistance || swipedDistance.y > swipeTriggerDistance;
        const swipeUp = swipedDistance.y < 0;
        const productListTouch =
            // if axis y swipe big enough consider it slider swiping
            Math.abs(swipedDistance.y) < 100
                ? swipedDistance.x > 5 || swipedDistance.x < -5
                : swipedDistance.x > 100 || swipedDistance.x < -100;
        const canSwipeProductList = !(isProductListComponent && productListTouch);
        if (sliderRef.current && canSwipe && canSwipeProductList) {
            if (isScrollComponent && !scrollTouched) {
                touch.y = newTouch.y;
                scrollTouched = true;
                return;
            }
            if (swipable) {
                if (swipeUp) {
                    if (backward) {
                        animateSwipe(activeElement - 1, 0.3, 150, 'ease-in');
                        resetTransform(activeElement - 1);
                        backward = false;
                        return;
                    }
                    headerRef.current.style.opacity = 1 - Math.abs(swipedDistance.y) * 0.002;
                    if (activeElement === 1 && lottieTransform) lottieTransform(swipedDistance.y);
                }
                const totalHeight = height + swipeDelay + 200; // 200 == height of clip-path polygon
                const active = swipeUp ? activeElement : activeElement - 1; // activeElements different when swipe up or down
                const transformedValue = swipeUp ? Math.abs(swipedDistance.y) : totalHeight - swipedDistance.y;
                sliderRef.current.children[active].style.transform = `translateY(-${transformedValue}px)`;
                backward = swipedDistance.y > 0;

                if (canChangeSlide) {
                    handleTouchStop(e, true);
                }
            }
        }
    };

    const handleTouchStop = (e, fullTranslated) => {
        if (e.touches.length > 1 || !isInPath(e,'sliderWrapper')) {
            return;
        }
        if (scrollTouched) {
            scrollTouched = false;
        }
        if (canSwipe) {
            const endPosition = e.changedTouches[0].clientY;
            const diff = touch.y - endPosition;
            const canDropAnimation = (diff > 0 && notLast) || (diff < 0 && notFirst);
            const swipeUp = diff > 0;
            //handle swipe up
            if (fullTranslated) {
                handleSwiping(diff);
                return;
            }
            if (canDropAnimation) {
                const active = swipeUp && diff < swipeTriggerDistance ? activeElement : activeElement - 1;
                const direction = swipeUp && diff < swipeTriggerDistance ? 0 : 150;
                animateSwipe(active, 0.3, direction, 'ease-in');
                if (swipeUp) {
                    setTimeout(() => {
                        resetTransform(active);
                        resetOpacity();
                    }, 300);
                    if (activeElement === 1 && lottieStop) lottieStop();
                }
            }
        }
    };

    const handleScrolling = useCallback((scrollEvent) => {
        const canScroll = scrollEvent.delta > 20 && scrollEvent.time - lastScrollTime > 600;
        const direction = scrollEvent.direction;
        if (canScroll && ((notLast && direction > 0) || (notFirst && direction < 0))) {
            handleChangeSlide(activeElement + direction, activeElement);
        }
    }, [activeElement, notFirst, notLast, lastScrollTime, handleChangeSlide])

    const handleSwiping = (swipeEvent) => {
        const direction = swipeEvent > 0 ? 1 : -1;
        handleChangeSlide(activeElement + direction, activeElement);
    };

    const onWheel = useCallback(
        (e) => {
            if (!isInPath(e,'sliderWrapper')) return
            
            const data = normalize(e);
            const newEvent = {
                time: new Date().getTime(),
                delta: Math.abs(data.pixelY),
                direction: Math.sign(data.spinY),
            };

            if (recentEvents.length >= 2) {
                recentEvents.shift();
            }

            const prevEvent = recentEvents.length ? recentEvents[recentEvents.length - 1] : undefined;
            recentEvents.push(newEvent);

            if (prevEvent) {
                if (
                    newEvent.direction !== prevEvent.direction ||
                    newEvent.delta > prevEvent.delta ||
                    newEvent.time > prevEvent.time + 150
                ) {
                    handleScrolling(newEvent);
                }
            }
        },
        [handleScrolling, recentEvents]
    );

    const normalize = (e) => {
        // Reasonable defaults
        let PIXEL_STEP = 10;
        let LINE_HEIGHT = 40;
        let PAGE_HEIGHT = 800;
        let sY = 0; // spinX, spinY

        let pY = 0; // pixelX, pixelY
        // Legacy

        if ('detail' in e) {
            sY = e.detail;
        }

        if ('wheelDelta' in e) {
            sY = -e.wheelDelta / 120;
        }

        if ('wheelDeltaY' in e) {
            sY = -e.wheelDeltaY / 120;
        }

        if ('axis' in e && e.axis === e.HORIZONTAL_AXIS) {
            sY = 0;
        }

        pY = sY * PIXEL_STEP;

        if ('deltaY' in e) {
            pY = e.deltaY;
        }

        if (pY && e.deltaMode) {
            if (e.deltaMode === 1) {
                // delta in LINE units
                pY *= LINE_HEIGHT;
            } else {
                // delta in PAGE units
                pY *= PAGE_HEIGHT;
            }
        } // Fall-back if spin cannot be determined

        if (pY && !sY) {
            sY = pY < 1 ? -1 : 1;
        }

        return {
            spinY: sY,
            pixelY: pY,
        };
    };

    useEffect(() => {
        //detect sliding forward
        if (headerRef.current) {
            resetOpacity();
        }
        if (activeElement > lastElement) {
            for (let i = 0; i < activeElement; i++) {
                animateSwipe(i, msAnimationTime, 150, 'ease-out')
            }
            return;
        }
        //detect sliding back
        if (activeElement < lastElement) {
            animateSwipe(activeElement, msAnimationTime, 0, 'ease-out');
            for (let i = children.length - 1; i > activeElement; i -= 1) {
                resetTransform(i);
                sliderRef.current.children[i].style.transform = null;
            }
        }
    }, [activeElement, lastElement, msAnimationTime, headerRef, resetOpacity, children.length]);

    return (
        <div className={css.sliderWrapper}>
            <div
                ref={sliderRef}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchStop}
                onWheel={onWheel}
                className={css.sliderContainer}
            >
                {childrenArray.map((child, index) => {
                    return (
                        <Slide key={child.key} zIndex={childrenArray.length - 1 - index} classes={`${css.sliderItem}`}>
                            {child}
                        </Slide>
                    );
                })}
            </div>
        </div>
    );
};

export default Slider;
